import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { registerLoginReducer } from './login'

const rootReducer = combineReducers({
  ...registerLoginReducer(),
})

const configureStore = () => {
  return createStore(
    rootReducer,
    composeWithDevTools(compose(applyMiddleware(thunkMiddleware))),
  )
}

export { rootReducer, configureStore }
