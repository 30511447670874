import * as authService from 'services/authentication'
import actions from './actions'

export default () => {
  return dispatch => {
    authService.destroyCredentials()

    return dispatch(actions.logout())
  }
}
