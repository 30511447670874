import moment from 'moment'
import { camelizeKeys } from 'humps'
import { LocalStorageError } from 'src/exceptions/localStorage'

const AUTH_SESSION = 'b2b-auth-session'

const getAuthSession = () => localStorage.getItem(AUTH_SESSION)

export const getCredentials = () => {
  const token = JSON.parse(getAuthSession())

  if (token === null) {
    throw new LocalStorageError("There's no session storage in local storage")
  }

  return camelizeKeys(token.authenticated)
}

const isTokenExpired = expiresAt => {
  return moment(expiresAt) < moment()
}

export const isLoggedIn = () => {
  try {
    const credentials = getCredentials()
    return (
      credentials.accessToken != null && !isTokenExpired(credentials.expiresAt)
    )
  } catch (e) {
    return false
  }
}

export const destroyCredentials = () => {
  localStorage.removeItem(AUTH_SESSION)
}

export const storeCredentials = (token, expiresAt) => {
  const session = {
    authenticated: {
      accessToken: token,
      expiresAt,
    },
  }
  localStorage.setItem(AUTH_SESSION, JSON.stringify(session))
}
