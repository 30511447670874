import React from 'react'
import { arrayOf, shape, string, number } from 'prop-types'
import { Helmet } from 'react-helmet'
import { addFontFace } from './addFontFace'

const propTypes = {
  fonts: arrayOf(
    shape({
      name: string,
      url: string,
      family: string,
      weight: number,
    }),
  ),
}

const defaultProps = {
  fonts: [],
}

const ImportFonts = ({ fonts }) => {
  return (
    <Helmet>
      {fonts.map(({ url }) => (
        <link
          key={url}
          rel="preload"
          href={url}
          as="font"
          type="font/woff2"
          crossOrigin="crossorigin"
        />
      ))}
      <style>{fonts.map(addFontFace).join('')}</style>
    </Helmet>
  )
}

ImportFonts.propTypes = propTypes
ImportFonts.defaultProps = defaultProps

export { ImportFonts }
