import { css } from '@creditas/stylitas'

const footerSticky = ({ sticky }) =>
  sticky &&
  css`
    position: sticky;
    margin-top: 10px;
  `

export { footerSticky }
