import { toSnackCase } from './cases'

const addFontFace = ({ family, weight, name, url }) => `
@font-face {
  font-family: '${family}';
  font-style: normal;
  font-display: swap;
  ${weight ? `font-weight: ${weight};` : ''}
  src: local('${name}'), local('${toSnackCase(name)}'), 
    url(${url}) format('woff2');
  unicode-range: U+0020-00FF;
}
`

export { addFontFace }
