function LocalStorageError(message) {
  this.name = 'LocalStorageError'
  this.message = message || ''
  this.stack = new Error().stack
}

LocalStorageError.prototype = Object.create(LocalStorageError.prototype)
LocalStorageError.prototype.constructor = LocalStorageError

export { LocalStorageError }
