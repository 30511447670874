import React, { useCallback } from 'react'
import { Header, HeaderItem } from '@creditas/header'
import { Button } from '@creditas/button'
import { navigate } from 'gatsby-link'
import { useDispatch, useMappedState } from 'redux-react-hook'
import { selectorLogin } from 'store/login'
import * as loginActions from 'store/login/actions'

const HeaderComponent = () => {
  const { loggedIn } = useMappedState(useCallback(selectorLogin))
  const dispatch = useDispatch()

  const logout = () => dispatch(loginActions.logout())

  return (
    <Header
      LogoProperties={{
        href: 'https://parceiros.creditas.com.br',
        title: 'Link to homepage',
        target: '_blank',
      }}
      toggleCallback={() => {}}
      gridAreas={{
        xs: `
        "languages languages languages"
        "mainNav mainNav mainNav"
        "actions actions actions"
      `,
        md: `
        "mainNav languages actions"
      `,
      }}
      gridColumns={{
        xs: '1fr auto auto',
        md: '65% 1fr auto',
      }}
      gridRows={{
        xs: '48px 1fr auto',
        md: '80px',
      }}
    >
      <HeaderItem area="actions">
        {loggedIn ? (
          <Button fullWidth={true} variant="secondary" onClick={logout}>
            Sair
          </Button>
        ) : (
          <Button
            fullWidth={true}
            variant="secondary"
            onClick={() => navigate('/login')}
          >
            Entrar
          </Button>
        )}
      </HeaderItem>
    </Header>
  )
}

export { HeaderComponent as Header }
