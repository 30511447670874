import moment from 'moment'
import i18n from 'i18next'
import * as authService from 'services/authentication'
import { authApi } from 'src/api'
import actions from './actions'

const requestLogin = (username, password) => {
  return authApi
    .post('/affiliates/tokens', { grantType: 'password', username, password })
    .then(response => response.data)
}

export default (username, password) => {
  return dispatch => {
    dispatch(actions.loginRequest())

    return requestLogin(username, password)
      .then(response => {
        const expiresAt = moment().add(response.expiresIn, 'seconds')

        authService.storeCredentials(response.accessToken, expiresAt.valueOf())

        dispatch(actions.loginSuccess())
      })
      .catch(({ response, message }) => {
        message = i18n.t('login:flash.error.api.unexpected')

        if (response && [400, 401].includes(response.status)) {
          message = i18n.t('login:flash.error.api.invalidCredentials')
        }

        dispatch(actions.loginFailure(message))
      })
  }
}
