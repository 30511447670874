import applyCaseMiddleware from 'axios-case-converter'
import axios from 'axios'

const authApi = applyCaseMiddleware(
  axios.create({
    baseURL: process.env.AUTH_DOMAIN_NAME,
  }),
)

export { authApi }
