export const ampleSoft100 = {
  name: 'AmpleSoft Thin',
  url: 'assets/fonts/amplesoft/amplesoft_thin-webfont.subset.woff2',
  family: 'AmpleSoft',
  weight: 100,
}

export const ampleSoft200 = {
  name: 'AmpleSoft Extra Light',
  url: 'assets/fonts/amplesoft/amplesoft_extralight-webfont.subset.woff2',
  family: 'AmpleSoft',
  weight: 200,
}

export const ampleSoft300 = {
  name: 'AmpleSoft Light',
  url: 'assets/fonts/amplesoft/amplesoft_light-webfont.subset.woff2',
  family: 'AmpleSoft',
  weight: 300,
}

export const ampleSoft400 = {
  name: 'AmpleSoft Regular',
  url: 'assets/fonts/amplesoft/amplesoft-webfont.subset.woff2',
  family: 'AmpleSoft',
  weight: 400,
}

export const ampleSoft500 = {
  name: 'AmpleSoft Medium',
  url: 'assets/fonts/amplesoft/amplesoft_medium-webfont.subset.woff2',
  family: 'AmpleSoft',
  weight: 500,
}

export const ampleSoft700 = {
  name: 'AmpleSoft Bold',
  url: 'assets/fonts/amplesoft/amplesoft_bold-webfont.subset.woff2',
  family: 'AmpleSoft',
  weight: 700,
}

export const workSans300 = {
  name: 'Work Sans Light',
  url: 'assets/fonts/worksans/WorkSans-Light.subset.woff2',
  family: 'Work Sans',
  weight: 300,
}

export const workSans400 = {
  name: 'Work Sans Regular',
  url: 'assets/fonts/worksans/WorkSans-Regular.subset.woff2',
  family: 'Work Sans',
  weight: 400,
}

export const workSans500 = {
  name: 'Work Sans Medium',
  url: 'assets/fonts/worksans/WorkSans-Medium.subset.woff2',
  family: 'Work Sans',
  weight: 500,
}

export const workSans600 = {
  name: 'Work Sans Semi Bold',
  url: 'assets/fonts/worksans/WorkSans-SemiBold.subset.woff2',
  family: 'Work Sans',
  weight: 600,
}

export const workSans700 = {
  name: 'Work Sans Bold',
  url: 'assets/fonts/worksans/WorkSans-Bold.subset.woff2',
  family: 'Work Sans',
  weight: 700,
}

export const nunito300 = {
  name: 'Nunito Light',
  url: 'assets/fonts/nunito/Nunito-Light.woff2',
  family: 'Nunito',
  weight: 300,
}

export const nunito400 = {
  name: 'Nunito Regular',
  url: 'assets/fonts/nunito/Nunito-Regular.woff2',
  family: 'Nunito',
  weight: 400,
}

export const nunito600 = {
  name: 'Nunito SemiBold',
  url: 'assets/fonts/nunito/Nunito-SemiBold.woff2',
  family: 'Nunito',
  weight: 600,
}

export const nunito700 = {
  name: 'Nunito Bold',
  url: 'assets/fonts/nunito/Nunito-Bold.woff2',
  family: 'Nunito',
  weight: 700,
}

export const nunitoSans300 = {
  name: 'Nunito Sans Light',
  url: 'assets/fonts/nunito/NunitoSans-Light.woff2',
  family: 'Nunito Sans',
  weight: 300,
}

export const nunitoSans600 = {
  name: 'Nunito Sans SemiBold',
  url: 'assets/fonts/nunito/NunitoSans-SemiBold.woff2',
  family: 'Nunito Sans',
  weight: 600,
}

export const nunitoSans700 = {
  name: 'Nunito Sans Bold',
  url: 'assets/fonts/nunito/NunitoSans-Bold.woff2',
  family: 'Nunito Sans',
  weight: 700,
}

export const fonts = [
  ampleSoft100,
  workSans300,
  workSans400,
  workSans500,
  workSans600,
  workSans700,
  nunito300,
  nunito400,
  nunito600,
  nunito700,
  nunitoSans300,
  nunitoSans600,
  nunitoSans700,
]
