const toCapitalize = str =>
  str &&
  str
    .toLowerCase()
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')

const toSnackCase = str =>
  str &&
  str
    .split(' ')
    .map(toCapitalize)
    .join('-')

export { toCapitalize, toSnackCase }
