import React from 'react'
import { useTranslation } from 'react-i18next'
import { Footer as FooterStyled, FooterGroup } from '@creditas/footer'
import { Typography } from '@creditas/typography'
import { styled } from '@creditas/stylitas'
import { footerSticky } from './footer.style'

const FooterRaw = props => {
  const { t } = useTranslation()

  return (
    <FooterStyled variant="internal" {...props}>
      <FooterGroup>
        <Typography variant="support">
          {t('commons:phrases.copyright')}
        </Typography>
      </FooterGroup>
    </FooterStyled>
  )
}

const Footer = styled(FooterRaw)`
  ${footerSticky}
`
Footer.displayName = 'Footer'

export { Footer }
