import { createReducer } from 'redux-act'
import { isLoggedIn } from 'services/authentication'
import { actions } from './actions'

const STATE_KEY = 'login'

const initialState = {
  loggingIn: false,
  loggedIn: isLoggedIn(),
  errorMessage: null,
}

const loginReducer = createReducer(
  {
    [actions.loginRequest]: state => {
      return { ...state, loggingIn: true }
    },
    [actions.loginSuccess]: () => {
      return { loggedIn: true }
    },
    [actions.loginFailure]: (_, error) => {
      return { errorMessage: error }
    },
    [actions.logout]: state => {
      return { ...state, loggedIn: false }
    },
  },
  initialState,
)

const registerLoginReducer = () => ({
  [STATE_KEY]: loginReducer,
})

const selectorLogin = state => state[STATE_KEY]

export { selectorLogin, registerLoginReducer }
