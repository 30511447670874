import commons from './commons'
import calculator from './calculator'
import login from './login'
import notFound from './not-found'

export default {
  commons,
  calculator,
  login,
  notFound,
}
