import React from 'react'
import { styled } from '@creditas/stylitas'
import { Container as ContainerStyled } from '@creditas/layout'
import { containerBase } from './container.style'

const ContainerRaw = props => <ContainerStyled {...props} />

const Container = styled(ContainerRaw)`
  ${containerBase}
`

Container.displayName = 'Container'

export { Container }
