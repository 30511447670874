import React from 'react'
import { node } from 'prop-types'
import { I18nextProvider } from 'react-i18next'
import { ThemeProvider } from '@creditas/stylitas'
import tokens from '@creditas/tokens'
import { Helmet } from 'react-helmet'
import { Container } from 'components/Container'
import { Global } from 'components/Global'
import { Header } from 'components/Header'
import { Footer } from 'components/Footer'
import { ImportFonts } from 'components/ImportFonts'
import { ToasterProvider } from '@creditas/toaster'
import { toPrefixUrl } from 'utils/toPrefixUrl'
import { fonts } from 'constants/fonts'
import i18n from 'src/i18n'
import { StoreContext } from 'redux-react-hook'
import { configureStore } from 'src/store'

const propTypes = {
  element: node.isRequired,
}

const App = ({ element }) => (
  <StoreContext.Provider value={configureStore()}>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={{ ...tokens }}>
        <ToasterProvider>
          <ImportFonts fonts={fonts.map(toPrefixUrl)} />
          <Global />
          <Header />
          <Helmet>
            <meta charSet="utf-8" />
            <title>Parceiros Creditas</title>
          </Helmet>
          <Container maxWidth="lg">{element}</Container>
          <Footer sticky />
        </ToasterProvider>
      </ThemeProvider>
    </I18nextProvider>
  </StoreContext.Provider>
)

App.propTypes = propTypes
App.displayName = 'App'

export { App }
